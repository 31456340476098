export default {
  data () {
    return {
      columns: [
        {
          type: 'index',
          title: '序号',
          width: 55,
          align: 'center'
        },
        {
          title: '基金名称',
          key: 'title',
          width: 400
        },
        {
          title: '负责人',
          key: 'master',
          width: 80
        },
        {
          title: '申请单位',
          key: 'institution',
          width: 150
        },
        // {
        //   title: '大类',
        //   key: 'category',
        //   width: 150
        // },
        {
          title: '大类学科',
          key: 'subject',
          width: 150
        },
        // {
        //   title: '小类学科',
        //   key: 'smallSubject',
        //   width: 150
        // },
        {
          title: '批准时间',
          key: 'approvalTime',
          align: 'center',
          formatter: (val) => { return val ? new Date(Date.regDate(val)).format('yyyy-MM-dd') : '' },
          width: 100
        },
        {
          title: '金额',
          key: 'money',
          width: 80
        },
        // {
        //   title: '学科代码',
        //   key: 'subjectCode',
        //   width: 100
        // },
        // {
        //   title: '项目批准号',
        //   key: 'projectNo',
        //   width: 100
        // },
        {
          title: '资助类型',
          key: 'researchType',
          width: 140
        }
        // {
        //   title: '关键词',
        //   key: 'keywords',
        //   width: 100
        // },
        // {
        //   title: '操作',
        //   key: 'action',
        //   width: 80,
        //   align: 'center',
        //   render: (h, params) => {
        //     return h('span', [
        //       h('button', {
        //         attrs: {
        //           class: 'table-actions-btn'
        //         },
        //         on: {
        //           click: () => { this.lookup(params) }
        //         }
        //       }, '详情')
        //     ])
        //   }
        // }
      ]
    }
  }
}
