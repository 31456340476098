export default {
  data () {
    return {
      selectionText: '',
      postStyle: {},
      showFundWin: false,
      hasChineseEeg: new RegExp(/[\u4e00-\u9fa5]+/),
      hasChinese: false
    }
  },
  created () {
    document.addEventListener('mousedown', this.mouseDown, false)
    document.addEventListener('mouseup', this.mouseUp, false)
  },
  methods: {
    mouseDown (e) {
      if (e.target.className.indexOf('s-item') >= 0) {
        e.stopPropagation()
        return false
      } else {
        this.showFundWin = false
        this.hasChinese = false
      }
    },
    mouseUp (e) {
      if (e.target.className.indexOf('s-item') >= 0) {
        e.stopPropagation()
        return false
      } else {
        let x = e.clientX
        let y = e.clientY
        this.postStyle = {left: (x - 20) + 'px', top: (y + 20) + 'px'}
        this.selectionText = this.getSelectionText()
        if (this.selectionText) {
          if (this.hasChineseEeg.test(this.selectionText)) {
            this.hasChinese = true
          } else {
            this.hasChinese = false
          }
          this.showFundWin = true
        }
      }
    },
    getSelectionText () {
      let text = ''
      if (document.selection) {
        text = document.selection.createRange().text
      } else if (window.getSelection) {
        text = window.getSelection().toString()
        if (text) text = text.trim()
      }
      return text
    },
    onCopy () {
      this.onCopyText(this.selectionText)
      this.showFundWin = false
    },
    onTranslateSearch () {
      this.translate(this.selectionText, en => {
        this.$router.push({name: 'paperSearch', query: {term: en}})
      })
    },
    onDirectSearch () {
      this.$router.push({name: 'paperSearch', query: {term: this.selectionText}})
    },
    onCopyText (content) {
      let input = document.createElement('input')
      input.setAttribute('value', content)
      input.style.position = 'absolute'
      input.style.left = '-9999px'
      document.body.appendChild(input)
      input.select()
      document.execCommand('copy')
      setTimeout(() => {
        document.body.removeChild(input)
      }, 1000)
    }
  },
  beforeDestroy () {
    document.removeEventListener('mousedown', this.mouseDown)
    document.removeEventListener('mouseup', this.mouseUp)
  }
}
