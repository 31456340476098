<template>
  <div class="cu-option-wrapper">
    <div class="cu-select-query">
      <input v-model="query" :placeholder="placeholder"
        @focus="handleQueryFocus"
        @change="handleQueryChange"/>
      <span v-if="clearable" class="clear-btn-wrapper" @click.stop="handleQueryClear">
        <span class="clear-box">
          <a class="clear-btn"></a>
        </span>
      </span>
    </div>
    <ul class="cu-option-list" v-show="showOption">
      <y-option ref="options" v-for="(item, index) in currentData" :key="item.id || index" :data="item"
        :labelField="labelField"
        :valueField="valueField"
        @on-option-select="onOptionSelect"></y-option>
    </ul>
  </div>
</template>
<script>
import YOption from './option'
import mixin from './mixin'
export default {
  components: {YOption},
  mixins: [mixin],
  props: {
    data: Array,
    value: [String, Number, Boolean],
    placeholder: String,
    labelField: String,
    valueField: String,
    clearable: Boolean,
    filterable: Boolean,
    loadMethod: Function
  },
  data () {
    return {
      query: '',
      showOption: false,
      queryChange: '',
      currentValue: this.value || '',
      currentData: this.data || []
    }
  },
  watch: {
    data (val) {
      this.currentData = val || []
      this.initQuery()
      this.updateOptionStatus(this.currentValue)
    },
    value (val) {
      this.currentValue = val || ''
      this.initQuery()
      this.updateOptionStatus(val)
    },
    query (val) {
      if (val) {
        // 监听到 query 实时变化（不是选择option选项），从后台返回过滤选项
        if (this.filterable && this.loadMethod && this.queryChange !== 'optionSelect') {
          this.queryChange = ''
          this.loadMethod(val, (data) => {
            this.currentData = data
            this.openOption()
          })
        }
      } else {
        // 监听到 query 被清空，修改value，以触发事件
        this.currentValue = ''
        if (this.filterable && this.loadMethod && this.queryChange !== 'handleClear' && this.queryChange !== 'oldRecover') {
          this.queryChange = ''
          this.loadMethod(val, (data) => {
            this.currentData = data
            this.openOption()
          })
        }
      }
      this.$emit('on-query-change', val)
    },
    currentValue (val) {
      this.$emit('input', val)
      this.$emit('on-change', val)
    }
  },
  mounted () {
    this.initQuery()
    this.updateOptionStatus(this.currentValue)
  },
  created () {
    document.addEventListener('click', this.handleQueryblur)
  },
  beforeDestroy () {
    document.removeEventListener('click', this.handleQueryblur)
  },
  methods: {
    initQuery () {
      // 根据currentValue 在 currentData 中匹配对应的label
      if (this.currentData && this.currentData.length && this.currentValue) {
        this.currentData.forEach(data => {
          if (this.currentValue === this.getValueField(data)) {
            this.queryChange = 'initQuery'
            this.query = this.getLabelField(data)
          }
        })
      }
    },
    handleQueryFocus () {
      this.openOption()
    },
    handleQueryblur (e) {
      if (!this.$el.contains(e.target)) {
        this.showOption = false
      }
    },
    handleQueryChange (e) {
      // query 被手动修改，并未清空，失去焦点后需要补回旧的显示（可能空，也可能原本有值）
      let oldQuery = ''
      this.currentData.forEach(data => {
        if (this.currentValue === this.getValueField(data)) {
          oldQuery = this.getLabelField(data)
        }
      })
      if (this.query && this.query !== oldQuery) {
        this.queryChange = 'oldRecover'
        this.query = oldQuery
      }
    },
    handleQueryClear () {
      this.queryChange = 'handleClear'
      this.query = ''
      this.closeOption()
    },
    onOptionSelect (item) {
      this.queryChange = 'optionSelect'
      this.query = this.getLabelField(item)
      this.currentValue = this.getValueField(item)
      this.updateOptionStatus(this.currentValue)
      this.closeOption()
    },
    openOption () {
      if (this.currentData && this.currentData.length) {
        this.showOption = true
      } else {
        this.showOption = false
      }
    },
    closeOption () {
      this.showOption = false
    },
    updateOptionStatus (currentValue) {
      let $options = this.$refs.options
      if ($options && $options.length) {
        $options.forEach($option => {
          $option.updateSelected(currentValue)
        })
      }
    }
  }
}
</script>
<style lang="less">
.cu-option-wrapper {
  position: relative;
}
.cu-select-query {
  position: relative;
  &> input {
    box-sizing: border-box;
    font-size: 0.8125rem;
    height: 32px;
    padding-left: 7px;
    padding-right: 32px;
    margin: 0;
    width: 100%;
    outline: 0;
    border: 1px solid #b6c3d0;
    border-radius: 3px;
    transition: border-color 0.2s;
    &:focus {
      border-color: #3988ff;
    }
  }
  &:hover {
    &> .clear-btn-wrapper {
      display: block;
    }
  }
  &> .clear-btn-wrapper {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    font-size: 70%;
    display: none;
  }
}
.cu-option-list {
  list-style: none;
  max-height: 480px;
  padding: 7px 0;
  position: absolute;
  z-index: 9999;
  top: 34px;
  left: 0;
  width: 100%;
  background-color: #fff;
  box-shadow: 0 1px 5px rgba(0, 0, 0, .45);
  border-radius: 4px;
  transition: max-height .2s;
  opacity: 1;
  overflow-y: auto;
}
.cu-option {
  font-size: 0.8125rem;
  height: 32px;
  line-height: 32px;
  padding: 0 10px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  &.active {
    background-color: #3988ff;
    color: #fff;
    &:hover {
      background-color: #3988ff;
      color: #fff;
    }
  }
  &:hover {
    background: #efefef;
  }
}
</style>
