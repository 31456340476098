<template>
  <div v-show="showModal" class="fund-context-win" :style="postStyle">
    <div class="s-list">
      <div class="s-item" @click.stop="copy">复制</div>
      <div class="s-item" @click.stop="translateSearch" v-if="hasChinese">翻译并查找相关文献</div>
      <div class="s-item" @click.stop="directSearch" v-else>查找相关文献</div>
    </div>
  </div>
</template>
<script>
import Modal from '../common/modal/modal'
export default {
  components: { Modal },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    postStyle: Object,
    hasChinese: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showModal: this.show
    }
  },
  watch: {
    show (val) {
      this.showModal = val
    },
    showModal (val) {
      if (!val) {
        this.$emit('on-hide')
      }
    }
  },
  methods: {
    copy () {
      this.$emit('on-copy')
    },
    translateSearch () {
      this.$emit('on-translateSearch')
    },
    directSearch () {
      this.$emit('on-directSearch')
    }
  }
}
</script>
<style lang="less">
.fund-context-win {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1050;
  outline: 0;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0,0,0,0.35);
}
.s-item {
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding: 0 15px;
  cursor: pointer;
  &:hover {
    background-color: #eee;
    color: #234567;
  }
}
</style>
