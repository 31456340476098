<template>
  <div>
    <div class="fund-search-box">
      <div class="page-box">
        <div class="fund-form">
          <div class="level-1">
            <div class="form-item">
              <span class="label content">
                <span class="fa fa-caret-down"></span>
                <span>基金名称：</span>
                <ul>
                  <li><input type="checkbox" value="title" disabled v-model="matchRange"/><span class="in">基金名称</span></li>
                  <li><input type="checkbox" value="keywords" v-model="matchRange"/><span class="in">关键词</span></li>
                  <li><input type="checkbox" value="abstract" v-model="matchRange"/><span class="in">摘要</span></li>
                </ul>
              </span>
              <y-input v-model="title" clearable placeholder="输入检索内容，多项内容可用空格、逗号分开" @on-enter="onQueryChange"></y-input>
            </div>
          </div>
          <div class="level-2">
            <div class="form-item">
              <span class="label">负责人：</span>
              <y-input v-model="master" clearable placeholder="可输入姓名拼音首字母" @on-enter="onQueryChange"></y-input>
            </div>
            <div class="form-item">
              <span class="label">基金类型：</span>
              <y-select
                v-model="researchType"
                :data="researchTypeList"
                clearable
                placeholder="请选择，默认所有"></y-select>
            </div>
          </div>
          <div class="level-2">
            <div class="form-item">
              <span class="label">申请单位：</span>
              <y-select
                v-model="institution"
                :data="institutionList"
                valueField="name"
                clearable
                filterable
                placeholder="可输入单位拼英首字母"
                :loadMethod="loadInstitutionList"></y-select>
            </div>
            <div class="form-item">
              <span class="label">大类学科：</span>
              <y-select
                v-model="subject"
                :data="subjectList"
                clearable
                filterable
                nameField="name"
                valueField="code"
                placeholder="输入学科，模糊匹配"
                :loadMethod="loadSubjectList"></y-select>
            </div>
          </div>
          <div class="level-btn">
            <a class="fund-nsfc" target="_blank" href="https://isisn.nsfc.gov.cn/egrantindex/funcindex/prjsearch-list">NSFC官网</a>
            <button class="fund-search-btn" @click.stop="onQueryChange">查 询</button>
          </div>
        </div>
      </div>
    </div>
    <div class="fund-options">
      <div class="page-box">
        <fund-option
          :showProjectNo="showProjectNo"
          :showMaster="showMaster"
          :showResearchType="showResearchType"
          :showKeywords="showKeywords"
          :showAbstract="showAbstract"
          :showAchievement="showAchievement"
          :showTable="showTable"
          @on-toggle-show-projectno="onToggleShowProjectNo"
          @on-toggle-show-master="onToggleShowMaster"
          @on-toggle-show-researchtype="onToggleShowResearchType"
          @on-toggle-show-keywords="onToggleShowKeywords"
          @on-toggle-show-abstract="onToggleShowAbstract"
          @on-toggle-show-achievement="onToggleShowAchievement"
          @on-toggle-show-table="onToggleShowTable"
          @on-sort-change="onSortChange"></fund-option>
      </div>
    </div>
    <div class="page-box fund-table">
      <div v-show="!showTable">
        <fund-item v-for="(item, index) in fundList"
          :index="pageIndex*pageSize+index+1"
          :key="item.id"
          :fund="item"
          :terms="terms"
          :showProjectNo="showProjectNo"
          :showMaster="showMaster"
          :showResearchType="showResearchType"
          :showKeywords="showKeywords"
          :showAbstract="showAbstract"
          :showAchievement="showAchievement"></fund-item>
      </div>
      <div v-show="showTable" style="padding-top: 10px;">
        <y-table :columns="columns" :data="fundList" wrap></y-table>
      </div>
    </div>
    <div class="page-box fund-pagination">
      <pagination pageSizeChangeable
        :total="sum"
        :pageIndex="pageIndex"
        :pageSize="pageSize"
        :placement="pageSizePlace"
        @on-currentpage-change="onPageChange"
        @on-pagesize-change="onPageSizeChange"></pagination>
    </div>
    <fund-win :show="showFundWin"
      :hasChinese="hasChinese"
      :postStyle="postStyle"
      @on-hide="showFundWin=false"
      @on-copy="onCopy"
      @on-translateSearch="onTranslateSearch"
      @on-directSearch="onDirectSearch"></fund-win>
    <loading-circle v-show="showLoading"></loading-circle>
    <tooltip-win :show="showTipWin" @on-hide="showTipWin=false" :text="tipText"></tooltip-win>
  </div>
</template>
<script>
import YTable from '../common/table/table'
import fundColumns from './mixins/columns'
import selection from './mixins/selection'
import translate from '../paper-search/mixins/translate'
import Pagination from '../common/pagination/pagination'
import YInput from '../common/input/input'
import YSelect from '../common/select/select'
import LoadingCircle from '../common/loading/loading-circle'
import FundItem from './fund-item'
import FundOption from './fund-option'
import FundWin from './fund-win'
import TooltipWin from '../login-tip-win/tooltip-win'
import { authenticate, catchExpired } from '../../utils/auth'
export default {
  components: {YTable, Pagination, YInput, YSelect, LoadingCircle, FundItem, FundOption, FundWin, TooltipWin},
  mixins: [fundColumns, selection, translate],
  data () {
    return {
      pageSizePlace: 'top',
      pageSize: 50,
      pageIndex: 0,
      fundList: [],
      institutionList: [],
      researchTypeList: [],
      subjectList: [],
      showLoading: false,

      title: '',
      master: '',
      institution: '',
      researchType: '',
      subject: '',
      sorts: ['-approvalTime'],
      matchRange: ['title'],
      terms: '',

      showProjectNo: false,
      showMaster: true,
      showResearchType: true,
      showKeywords: true,
      showAbstract: false,
      showAchievement: false,
      showTable: false,

      showTipWin: false,
      tipText: ''
    }
  },
  computed: {
    tableHeight () {
      return this.$store.state.win.winHeight - 140 - (window.$upgrade ? 22 : 0)
    }
  },
  watch: {
    matchRange (val) {
      console.log('matchRange', val)
    }
  },
  mounted () {
    document.title = 'NSFC 基金查询'
    this.loadInstitutionList()
    this.loadResearchTypeList()
    this.loadSubjectList()
  },
  methods: {
    loadFundList2 () {
      this.$http.get(`${this.httpRoot}/fund/list`, authenticate({
        params: {
          title: this.title,
          master: this.master,
          institution: this.institution,
          researchType: this.researchType,
          subject: this.subject,
          matchRange: this.matchRange,
          sorts: this.sorts,
          pageIndex: this.pageIndex,
          pageSize: this.pageSize
        }
      }))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.fundList = res.fundList || []
          this.sum = res.sum || 0
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadFundList () {
      this.showLoading = true
      this.$http.post(`${this.httpRoot}/fund/list`, {
        title: this.title,
        master: this.master,
        institution: this.institution,
        researchType: this.researchType,
        subject: this.subject,
        matchRange: this.matchRange,
        sorts: this.sorts,
        pageIndex: this.pageIndex,
        pageSize: this.pageSize
      }, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.showLoading = false
          this.fundList = res.fundList || []
          this.sum = res.sum || 0
          if (typeof res.terms === 'string') {
            this.terms = res.terms
          } else if (Array.isArray(res.terms)) {
            this.terms = res.terms.join('/')
          }
          if (this.sum > 3) {
            this.pageSizePlace = 'top'
          } else {
            this.pageSizePlace = 'bottom'
          }
        } else {
          this.showLoading = false
          this.tipText = res.statusText
          this.showTipWin = true
        }
      })
      .catch(err => {
        catchExpired(err, this)
        this.showLoading = false
      })
    },
    loadInstitutionList (query, callback) {
      this.$http.get(`${this.httpRoot}/institution/list`, authenticate({params: {query}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.institutionList || [])
          } else {
            this.institutionList = res.institutionList || []
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadResearchTypeList () {
      this.$http.get(`${this.httpRoot}/researchtype/list`, authenticate())
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          this.researchTypeList = res.researchTypeList || []
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    loadSubjectList (query, callback) {
      this.$http.get(`${this.httpRoot}/subject/list`, authenticate({params: {query}}))
      .then(res => res.json())
      .then(res => {
        if (res.status === 'success') {
          if (typeof callback === 'function') {
            callback(res.subjectList || [])
          } else {
            this.subjectList = res.subjectList || []
          }
        }
      })
      .catch(err => {
        catchExpired(err, this)
      })
    },
    onPageChange (page) {
      this.pageIndex = page - 1
      document.querySelector('.fund-search-box').scrollIntoView()
      this.loadFundList()
    },
    onPageSizeChange (size) {
      this.pageIndex = 0
      this.pageSize = size
      this.loadFundList()
    },
    onQueryChange (val) {
      this.pageIndex = 0
      this.loadFundList()
    },
    onToggleShowProjectNo () {
      this.showProjectNo = !this.showProjectNo
    },
    onToggleShowMaster () {
      this.showMaster = !this.showMaster
    },
    onToggleShowResearchType () {
      this.showResearchType = !this.showResearchType
    },
    onToggleShowKeywords () {
      this.showKeywords = !this.showKeywords
    },
    onToggleShowAbstract () {
      this.showAbstract = !this.showAbstract
    },
    onToggleShowAchievement () {
      this.showAchievement = !this.showAchievement
    },
    onToggleShowTable () {
      this.showTable = !this.showTable
    },
    onSortChange (sorts) {
      this.sorts = sorts
      this.pageIndex = 0
      this.loadFundList()
    }
  }
}
</script>
<style lang="less">
.fund-search-box {
  background-color: #f1f1f1;
  border-bottom: 1px solid #ccc;
}
.fund-options {
  border-bottom: 1px solid #ccc;
}
.fund-form {
  padding: 15px 50px;
}
.level-1 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
  &>.form-item {
    width: 100%;
    &> .label + div {
      width: 82.5%;
    }
    &> .label {
      position: relative;
      &:hover {
        ul {
          display: block;
        }
      }
      ul {
        position: absolute;
        display: none;
        top: 32px;
        right: 0;
        text-align: right;
        background-color: #f1f1f1;
        box-shadow: 0 0px 5px rgba(0, 0, 0, 0.35);
        li {
          list-style: none;
          height: 32px;
          line-height: 32px;
          padding: 0 14px;
          display: flex;
          align-items: center;
          text-align: left;
        }
      }
      .in {
        width: 60px;
        cursor: pointer;
      }
    }
  }
}
.level-2 {
  display: flex;
  justify-content: space-between;
  margin-bottom: 22px;
}
.form-item {
  width: 50%;
  height: 32px;
  line-height: 32px;
  display: flex;
  justify-content: flex-start;
  font-size: 13px;
  &> .label {
    width: 100px;
    text-align: right;
    font-size: 13px;
    &+ div {
      width: 65%;
    }
  }
}
.level-btn {
  padding-left: 100px;
  padding-right: 73px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a.fund-nsfc {
  font-size: 13px;
  &:active, &:hover, &:visited {
    color: #404c53;
  }
}
.fund-search-btn {
  font-size: 0.875rem;
  line-height: 24px;
  padding: 3px 60px;
  background-color: #037dce;
  border: 1px solid #0a76be;
  transition: background-color .15s;
  cursor: pointer;
  color: #fff;
  outline: 0;
  &:active {
    background-color: #0071bc;
    border: 1px solid #0071bc;
  }
}
.fund-pagination {
  padding: 10px 0;
}
</style>
