<template>
  <li class="cu-option" :class="{active: selected}" @click.stop="handleClick">
    <span>{{ showLabel }}</span>
  </li>
</template>
<script>
import mixin from './mixin'
export default {
  mixins: [mixin],
  props: {
    data: [Object, String, Number],
    labelField: String,
    valueField: String
  },
  data () {
    return {
      selected: false
    }
  },
  computed: {
    showLabel () {
      return this.getLabelField(this.data)
    }
  },
  methods: {
    handleClick () {
      this.$emit('on-option-select', this.data)
    },
    updateSelected (value) {
      this.selected = (value === this.getValueField(this.data))
    }
  }
}
</script>
