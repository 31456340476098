<template>
  <div class="fund-item">
    <div class="index">{{index}}</div>
    <div class="title" v-highlight:[terms]="fund.title"></div>
    <div class="project" v-if="showProjectNo">
      <span class="proj-no">
        <span class="label">批准号：</span><span>{{fund.projectNo}}</span>
      </span>
    </div>
    <div class="master" v-if="showMaster">
      <span class="approval-time" title="批准时间">{{fund.approvalTime}}</span>
      <span class="master-name">
        <span><span class="label">负责人：</span>{{fund.master}}</span>
        <span class="master-title" v-if="fund.masterTitle">{{fund.masterTitle}}</span>
        <!-- <span class="master-title">副主任医师</span> -->
      </span>
      <span class="master-money">
        <span class="label">金额：</span>{{fund.money}}
      </span>
      <span class="master-institution">
        <span class="label">申请单位：</span><span>{{fund.institution}}</span>
      </span>
    </div>
    <!-- <div class="re-date">
      <span class="duration">研究期限：{{fund.researchDates}}</span>
    </div> -->
    <div class="re-type-subject" v-if="showResearchType">
      <span class="re-type">
        <span class="label">基金类型：</span><span>{{fund.researchType}}</span>
      </span>
      <span class="category">
        <span class="label">大类：</span><span>{{fund.category}}</span>
      </span>
      <span class="subject">
        <span class="label">大类学科：</span><span>{{fund.subject}}</span>
      </span>
    </div>
    <div class="keywords" v-if="showKeywords && (fund.keywords || fund.chKeywords)"><span class="label">关键词：</span>{{fund.keywords || fund.chKeywords}}</div>
    <div class="keywords" v-if="showKeywords && fund.enKeywords"><span class="label">Keywords：</span>{{fund.enKeywords}}</div>
    <div class="ch-abstract" v-if="showAbstract && fund.chAbstract">
      <span class="label">中文摘要：</span>
      <div>{{fund.chAbstract}}</div>
    </div>
    <div class="en-abstract" v-if="showAbstract && fund.enAbstract">
      <span class="label">英文摘要：</span>
      <div class="content">{{fund.enAbstract}}</div>
    </div>
    <div class="final-abstract" v-if="showAbstract && fund.finalAbstract">
      <span class="label">结题摘要：</span>
      <div>{{fund.finalAbstract}}</div>
    </div>
    <div class="achieves" v-if="showAchievement && fund.achieves && fund.achieves.length">
      <span class="label">研究成果：</span>
      <div class="achieves-item" v-for="(item, index) in fund.achieves" :key="index">
        <span>{{index+1}}.</span>
        <span class="achieve-title">{{item.title}}</span>
        <span class="authors">{{item.authors}}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Highlight from '../common/directives/highlight'
export default {
  directives: {highlight: Highlight},
  props: {
    fund: {
      type: Object,
      default () { return {} }
    },
    index: [String, Number],
    terms: String,
    showProjectNo: {
      type: Boolean,
      default: false
    },
    showMaster: {
      type: Boolean,
      default: true
    },
    showResearchType: {
      type: Boolean,
      default: true
    },
    showKeywords: {
      type: Boolean,
      default: true
    },
    showAbstract: {
      type: Boolean,
      default: false
    },
    showAchievement: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {}
  },
  methods: {
    //
  }
}
</script>
<style lang="less">
.fund-item {
  position: relative;
  margin: 10px 0;
  padding-left: 30px;
  .index {
    position: absolute;
    height: 22px;
    line-height: 22px;
    top: 0;
    left: 0;
    font-weight: 700;
    color: #b41b1b;
  }
  .title {
    color: #0e3e7b;
    font-size: 15px;
    font-weight: 700;
    font-family: Helvetica Neue,Helvetica,PingFang SC,Tahoma,Arial,sans-serif;
    height: 24px;
    .highlight {
      color: #be4747;
    }
  }
  .project {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
  }
  .master {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    .approval-time {
      width: 60px;
      color: #660606;
    }
    .master-name {
      min-width: 180px;
    }
    .master-title {
      font-size: 12px;
      color: #888;
    }
    .master-money {
      min-width: 230px;
    }
    .master-institution {
      min-width: 300px;
    }
  }
  .re-type-subject {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    .re-type {
      min-width: 240px;
    }
    .category {
      min-width: 230px;
    }
  }
  .keywords {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 2px;
    font-size: 14px;
  }
  .ch-abstract, .en-abstract, .final-abstract, .achieves {
    margin-top: 2px;
    font-size: 14px;
    .content {
      overflow: hidden;
    }
    .achieve-title {
      color: #232323;
    }
    .authors {
      color: #666;
      font-size: 12px;
    }
  }
  .label {
    color: #888;
  }
}
</style>
