export default {
  methods: {
    getLabelField (data = {}) {
      if (typeof data === 'string' || typeof data === 'number') {
        return data
      }
      if (typeof data === 'object') {
        let labelField = this.labelField || 'label'
        return data[labelField] || data.label || data.name || data.title || ''
      }
      return ''
    },
    getValueField (data = {}) {
      if (typeof data === 'string' || typeof data === 'number') {
        return data
      }
      if (typeof data === 'object') {
        let valueField = this.valueField || 'value'
        return data[valueField] || data.value || data.id || data.code || ''
      }
      return ''
    }
  }
}
