<template>
  <div class="fund-option">
    <div class="fd-sort">
      <div class="fd-sort-btn">
        <span class="label"><span style="padding-right: 4px;" class="fa fa-angle-down"></span>优先排序：</span>
        <div class="sort-item-list">
          <span @click.stop="toggleSortItem(item)" v-for="(item, index) in sortItems" :key="index" class="sort-item" :class="{selected: item.selected}">
            <span>{{item.name}}</span>
            <span v-if="item.value==='-approvalTime' || item.value==='-money2'" class="fa fa-long-arrow-down"></span>
            <span class="icon fa" :class="[item.selected?'fa-check':'fa-plus']"></span>
          </span>
        </div>
      </div>
      <div class="fd-sorted">
        <span @click.stop="cancelSortItem(item)" v-for="(item, idx) in sortList" :key="idx" class="selected-item">
          <span>{{item.name}}</span>
          <span v-if="item.value==='-approvalTime' || item.value==='-money2'" class="fa fa-long-arrow-down"></span>
          <span class="icon fa fa-close"></span>
        </span>
      </div>
    </div>
    <div class="fd-filter">
      <div class="fd-filter-btn">
        <span class="label">显示设置<span style="padding-left: 4px;" class="fa fa-cog"></span></span>
        <ul>
          <li @click.stop="handleShowMaster">{{showMaster?'隐藏负责人和单位':'显示负责人和单位'}}</li>
          <li @click.stop="handleShowResearchType">{{showResearchType?'隐藏基金类型和学科':'显示基金类型和学科'}}</li>
          <li @click.stop="handleShowKeywords">{{showKeywords?'隐藏关键词':'显示关键词'}}</li>
          <li @click.stop="handleShowAbstract">{{showAbstract?'隐藏摘要':'显示摘要'}}</li>
          <li @click.stop="handleShowProjectNo">{{showProjectNo?'隐藏批准号':'显示批准号'}}</li>
          <li @click.stop="handleShowAchievement">{{showAchievement?'隐藏成果':'显示成果'}}</li>
          <li @click.stop="handleShowTable">{{showTable?'列表显示':'表格显示'}}</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    showProjectNo: {
      type: Boolean,
      default: false
    },
    showMaster: {
      type: Boolean,
      default: true
    },
    showResearchType: {
      type: Boolean,
      default: true
    },
    showKeywords: {
      type: Boolean,
      default: true
    },
    showAbstract: {
      type: Boolean,
      default: false
    },
    showAchievement: {
      type: Boolean,
      default: false
    },
    showTable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sortList: [{name: '批准时间', value: '-approvalTime'}],
      sortItems: [
        // {name: '批准时间', value: 'approvalTime', selected: true},
        {name: '批准时间', value: '-approvalTime', selected: true},
        // {name: '资助金额', value: 'money2', selected: false},
        {name: '资助金额', value: '-money2', selected: false},
        {name: '基金类型', value: 'researchType', selected: false},
        {name: '申请单位', value: 'institution', selected: false},
        {name: '大类', value: 'category', selected: false},
        {name: '大类学科', value: 'subject', selected: false}
      ]
    }
  },
  methods: {
    handleShowProjectNo () {
      this.$emit('on-toggle-show-projectno')
    },
    handleShowMaster () {
      this.$emit('on-toggle-show-master')
    },
    handleShowResearchType () {
      this.$emit('on-toggle-show-researchtype')
    },
    handleShowKeywords () {
      this.$emit('on-toggle-show-keywords')
    },
    handleShowAbstract () {
      this.$emit('on-toggle-show-abstract')
    },
    handleShowAchievement () {
      this.$emit('on-toggle-show-achievement')
    },
    handleShowTable () {
      this.$emit('on-toggle-show-table')
    },
    toggleSortItem (item) {
      let found = false
      this.sortList.forEach(sitem => {
        if (sitem.value === item.value) {
          found = true
        }
      })
      if (!found) {
        item.selected = true
        this.sortList.push(item)
        this.emitSortChange()
      }
    },
    cancelSortItem (item) {
      let index = -1
      this.sortList.forEach((sitem, idx) => {
        if (sitem.value === item.value) {
          index = idx
        }
      })
      if (index >= 0) {
        this.sortList.splice(index, 1)
      }
      this.sortItems.forEach(sitem => {
        if (sitem.value === item.value) {
          sitem.selected = false
        }
      })
      this.emitSortChange()
    },
    emitSortChange () {
      let sorts = this.sortList.map(item => item.value)
      this.$emit('on-sort-change', sorts)
    }
  }
}
</script>
<style lang="less">
.fund-option {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  font-size: 14px;
  &:after {
    content: '.';
    height: 0;
    display: block;
    visibility: hidden;
    clear: both;
  }
}
.fd-sort {
  float: left;
  display: flex;
}
.fd-sort-btn {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  .label {
    display: inline-block;
    height: 40px;
    line-height: 40px;
  }
  &:hover {
    color: #278ef5;
    .sort-item-list {
      display: block;
    }
  }
  .sort-item-list {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 40px;
    width: 550px;
    display: none;
    color: #232323;
    font-size: 13px;
    background: #fff;
    box-shadow: 0 0px 5px rgba(0, 0, 0, 0.35);
    &>.sort-item {
      float: left;
      height: 36px;
      line-height: 36px;
      white-space: nowrap;
      padding: 0 10px;
      &:hover {
        background: #eee;
        color: #278ef5;
      }
      .icon {
        padding-left:4px;
        font-size: 10px;
      }
      &.selected {
        cursor:not-allowed;
        color: #278ef5;
        &:hover {
          background: #fff;
          color: #278ef5;
        }
      }
    }
    &:after {
      content: '.';
      height: 0;
      display: block;
      visibility: hidden;
      clear: both;
    }
  }
}
.fd-sorted {
  height: 40px;
  line-height: 40px;
  display: flex;
  font-size: 13px;
  .selected-item {
    cursor: pointer;
    padding: 0 10px;
    &:hover {
      color: rgb(199, 39, 39);
      background: #eee;
    }
    .icon {
      padding-left: 4px;
      font-size: 10px;
    }
  }
}
.fd-filter {
  float: right;
}
.fd-filter-btn {
  position: relative;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
  .label {
    display: inline-block;
    height: 40px;
    line-height: 40px;
  }
  &:hover {
    color: #278ef5;
    ul {
      display: block;
    }
  }
  ul {
    position: absolute;
    z-index: 1;
    right: 0;
    top: 40px;
    display: none;
    color: #232323;
    background: #fff;
    box-shadow: 0 0 3px rgba(60,60,60,0.35);
    &>li {
      list-style: none;
      height: 32px;
      line-height: 32px;
      white-space: nowrap;
      padding: 0 15px;
      &:hover {
        background: #eee;
        color: #278ef5;
      }
    }
  }
}
</style>
