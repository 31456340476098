function mark (text, translation, title) {
  let p = text || ''
  let terms = translation.split('/')
  if (terms.length) {
    terms.forEach(term => {
      let termLowCase = term.toLocaleLowerCase()
      let reg = new RegExp('(' + term + ')', 'gmi')
      let array = p.split(reg)
      let _p = ''
      if (array.length > 1 && term) {
        array.forEach(item => {
          if (item.toLocaleLowerCase() === termLowCase) {
            if (title) {
              _p += '<a class="highlight title">' + item + '</a>'
            } else {
              _p += '<a class="highlight">' + item + '</a>'
            }
          } else {
            _p += item
          }
        })
        p = _p
      }
    })
    return p
  } else {
    return text
  }
}

export default {
  bind (el, binding, vnode) {
    let title = false
    if (binding.modifiers && binding.modifiers.t) title = true
    let pHtml = mark(binding.value, binding.arg, title)
    el.innerHTML = pHtml
  },
  update (el, binding, vnode) {
    // let title = false
    // if (binding.modifiers && binding.modifiers.t) title = true
    // let pHtml = mark(binding.value, binding.arg, title)
    // el.innerHTML = pHtml
  },
  unbind (el, binding) {
    //
  }
}
